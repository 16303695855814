.CardClassificacao
  &> div
    flex-direction: column
    .infos_gerais
      gap: 20px
      color: var(--white)
      display: flex
      padding: 5px 5px 5px 15px
      background: var(--color)
      align-items: center
      border-radius: 0
      margin: -10px -10px 0px 
      justify-content: space-between
      .qnt
        gap: 10px
        display: flex
        font-size: 20px
        align-items: center
        font-weight: bold
      .pesos
        gap: 10px
        flex: 2
        color: var(--colorText)
        display: flex
        padding: 5px 10px
        background: var(--white)
        align-items: center
        border-radius: 5px
        justify-content: space-between
        span
          gap: 5px
          display: flex
          align-items: center
      .expansion-button
        color: var(--white)
      
    .cards
      gap: 10px
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      .card
        cursor: pointer
        transition: $transition
        &.active
          background: var(--colorOff)
        &:hover
          box-shadow: var(--elevation2expand)
        &.NORMAL
        &.WARNING
          border-left: 5px solid $colorWarning
        &.DANGER
          border-left: 5px solid $colorDanger
        &.SUCCESS
          border-left: 5px solid $colorSuccess
        &.INFO
          border-left: 5px solid $colorInfo
        
        .title
          gap: 10px
          display: flex
          padding: 10px
          font-size: 20px
          align-items: center
          line-height: 20px
        .content
          gap: 20px
          height: 100%
          display: flex
          padding: 5px 10px
          align-items: center
          .min
            flex: 1
            display: flex
            text-align: left
            flex-direction: column
            span
              text-align: center
          .med
            flex: 1
            display: flex
            text-align: left
            flex-direction: column
            span
              text-align: center
              &:first-child
                font-weight: bold
                font-size: 30px
                line-height: 30px
          .max
            flex: 1
            display: flex
            text-align: left
            flex-direction: column
            span
              text-align: center
        .footer
          color: var(--colorText)
          padding: 5px 10px
          font-size: 12px
          text-align: right
