.truckChargingFull
  outline-style: solid
  animation: TruckChargingFull 1.5s ease infinite

@keyframes TruckChargingFull
  0%
    outline-width: 0px
    outline-offset: 0px
    outline-color: rgb(220, 0, 20)
  100%
    outline-width: 40px
    outline-offset: 3px
    outline-color: transparent

