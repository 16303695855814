$spacing: 5px
$colorTitle: #f0f0f0
$colorTitleB: #545454
$colorText: #333
$colorTextB: #eee
$colorTextGray: #999
$colorOff: #ddd
$colorOffB: #666
$color: #101820
$colorAlpha: #10182088
$colorAlpha2: #10182033
$colorLight: #989fa8
$colorGray: #888

$colorB: #efe8df
$colorBAlpha: #efe8df88
$colorBAlpha2: #efe8df33
$colorBLight: #bcb4ac
$color2: #00b8a0
$color3: #b8a000
$white: #fefefe
$black: #313131
$colorPrimary: $color
$padding: 10px
$gap: 5px
$borderRadius: 4px
$transition: .3s ease all
$background: #f8f8f8
$backgroundDisabled: #E9E9E9
$backgroundError: #F8ECEB
$backgroundSuccess: #D6FEF8
$backgroundWarning: #FFEEDA
$backgroundInfo: #CEE6FF
$backgroundB: #080808
$colorBGray: #bcb4ac

$elevation0: 0px 0px 1px $colorOff
$elevation1: 1px 1px 3px $colorOff
$elevation2: 1px 1px 2px $colorOff, 1px 1px 3px fade-out($colorOff, .5), 1px 1px 5px fade-out($colorOff, .7)
$elevation2expand: 1px 1px 4px $colorOff, 1px 1px 5px fade-out($colorOff, .3), 1px 1px 7px fade-out($colorOff, .4)
$elevation3: 0 1px 5px rgb(0 0 0 / 25%)
$elevationChart: 1px 1px 3px rgb(0 0 0 / 25%)
$elevationModal: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)
$textShadow: 1px 1px 1px $colorOff

$elevation0B: 0px 0px 1px $colorOffB
$elevation1B: 1px 1px 3px $colorOffB
$elevation2B: 1px 1px 2px $colorOffB, 1px 1px 3px fade-out($colorOffB, .5), 1px 1px 5px fade-out($colorOffB, .7)
$elevation2expandB: 1px 1px 4px $colorOffB, 1px 1px 5px fade-out($colorOffB, .3), 1px 1px 7px fade-out($colorOffB, .4)
$elevation3B: 0 1px 5px rgb(255 255 255 / 75%)
$elevationChartB: 1px 1px 3px rgb(255 255 255 / 25%)
$elevationModalB: 0px 11px 15px -7px rgba(150, 150, 150, 0.2), 0px 24px 38px 3px rgba(150, 150, 150, 0.14), 0px 9px 46px 8px rgba(150, 150, 150, 0.12)
$textShadowB: 1px 1px 1px $colorOffB

$colorInfo: #00c1de
$colorInfoLight: #00c1de22
$colorWarning: #e0973d
$colorWarningrLight: #e0973d22
$colorDanger: #d04933
$colorDangerLight: #d0493322
$colorSuccess: #00bca0
$colorSuccessLight: #00bca022
