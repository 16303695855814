.daterange-picker
  input
    cursor: pointer

  .daterange-picker-selector
    overflow: hidden
    display: flex
    justify-content: space-between
    gap: $gap
    position: absolute
    background: var(--white)
    z-index: 1
    border: 2px solid var(--color)
    //width: 650px
    top: 42px
    border-radius: $borderRadius
    box-shadow: var(--elevation3)
    &> div:last-child
      display: flex
    .daterange-picker-presents
      flex: 1
      gap: $gap
      display: flex
      flex-direction: column
      button
        white-space: nowrap
        justify-content: left
        width: 100%
        max-width: 100%
        background: none
        border-radius: 0
        color: var(--color)
        transition: $transition
        box-shadow: none
        text-shadow: none
        &:hover
          background: var(--color)
          color: var(--white)
    .react-datepicker
      display: flex
      border: none
      background-color: var(--white)
      color: var(--color)
      .react-datepicker__navigation
        max-width: initial
        box-shadow: none
        text-shadow: none
        span
          &:before
            border-color: var(--white)
      .react-datepicker__header 
        background-color: var(--color)
        border-radius: 0
        *
          color: var(--white)
      .react-datepicker__day
        color: var(--color)
        &:hover
          background: var(--colorAlpha2)
          box-shadow: var(--elevation1)
      .react-datepicker__day--selected
        background: var(--color)
        color: var(--white)
      .react-datepicker__day--in-range
        background: var(--colorAlpha2)
        color: var(--color)
      .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)
        background: var(--colorAlpha2)
        color: var(--color)
      .react-datepicker__month-container
        &:nth-child(5)
          border-right: 1px solid var(--color)
          .react-datepicker__header
            border-bottom-left-radius: $borderRadius
            //border-bottom-right-radius: $borderRadius
        .react-datepicker__header
          box-shadow: var(--elevation2)
            