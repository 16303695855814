.nothing-here
  background-color: var(--colorOff)
  border-radius: 5px
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  color: var(--colorTextGray)
  font-weight: bold
  flex: 1
  margin: 10px 0
  padding: 10px 15px
  text-align: center
