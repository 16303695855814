.text
  font-family: 'Roboto', sans-serif
  font-size: 1rem
  line-height: 1.6

  .custom-strong
    font-weight: bold
    color: black
    background-color: #f5f5f5
    padding: 0.2em 0.4em
    border-radius: 0.3em
