.container
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  .divider-vertical
    height: 80px
    width: 1px
    background: gray
    margin: 0 10px
