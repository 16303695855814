.details-container
  display: flex
  flex-wrap: wrap
  width: 100%
  justify-content: space-between

  .details-column
    margin: 0 1rem
    display: flex
    flex-direction: column
    justify-content: center
    flex-wrap: wrap
    h3
      font-weight: bold
      margin: 0.5em 0

    div.form-group
      margin-bottom: 0.5rem
      width: 100%
