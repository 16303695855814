.via-svg
  width: 24px
  display: block
  height: auto
  cursor: pointer
  transition: transform 0.2s ease-in-out
  animation: pulse 1.5s infinite

@keyframes pulse
  0%
    transform: scale(1)
    opacity: 1
  50%
    transform: scale(1.1)
    opacity: 0.8
  100%
    transform: scale(1)
    opacity: 1

.via-svg-disabled
  cursor: not-allowed
  filter: grayscale(100%)
  opacity: 0.5
