.lockscreen
  position: fixed
  inset: 0
  z-index: 100
  backdrop-filter: brightness(0.8) blur(1.1px)
  display: flex
  align-items: center
  justify-content: center
  transition: $transition
  animation: fade-in .2s ease forwards

  .modal
    position: absolute
    border: 2px solid var(--color)
    min-width: 400px
    max-height: 100%
    background: var(--white)
    display: flex
    justify-content: space-between
    flex-direction: column
    transition: $transition
    max-width: 75%
    border-radius: $borderRadius
    overflow: hidden
    box-shadow: var(--elevationModal)
    opacity: 0
    animation: fade-in-blur .2s ease .1s forwards
    &.middle-width
      width: 50vw
    &.large-width
      width: 90vw
    &.full-width
      width: 100%
    .modal-header
      background: var(--colorTitle)
      //text-shadow: 2px 2px 1px var(--white), -2px 2px 1px var(--white), 2px -2px 1px var(--white), -2px -2px 1px var(--white)
      padding: 10px
      font-size: 16px
      font-weight: bold
      display: flex
      gap: $padding
      justify-content: space-between
      align-items: center
      border-bottom: 1px solid var(--colorOff)
      .modal-title
        flex: 1
      .iconButton
        color: var(--black)
        &:active
          color: var(--white)
    .modal-stepper
      border-bottom: 1px solid var(--colorOff)
    .modal-content
      align-content: start
      gap: 5px
      display: flex
      flex-direction: column
      flex: 1
      overflow-y: auto
      border-bottom: 1px solid var(--colorOff)
      padding: 10px
      .frame
        background: var(--background)
        .frame-titleBar
          h3
            padding: $gap
    .modal-buttons
      background: var(--background)
      display: flex
      justify-content: space-between
      .modal-buttons-main, .modal-buttons-extra
        padding: 10px
        display: flex
        justify-content: flex-end
        gap: 10px
        .display-cancel
          padding: 5px 10px 5px 10px
        button, .button
          color: var(--white)

  &.closing
    animation: fade-out .2s ease .2s forwards
    .modal
      animation: fade-out-blur .2s ease forwards
