.ticket-carimbo
  background: white
  color: black
  padding: 5

  .ticket-carimbo-header
    width: 540px
    border: 1px solid black
    margin: 0 auto
    .ticket-carimbo-codigoDeBarras
      text-align: left
      width: 50%
    .ticket-carimbo-codigoDeBarras-right
      text-align: right
      width: 50%

  .ticket-carimbo-title
    font-size: 18px
    font-weight: bold

  .ticket-carimbo-width
    width: 100%

  .ticket-carimbo-obs
    fontWeight: bold

  .ticket-carimbo-assinatura
    text-align: center

    .ticket-carimbo-assinatura-line
      width: 50%

    .ticket-carimbo-codigoDeBarras-bottom
      width: 540
      padding: 2
      margin: 0 auto
