:root
  --gap: #{$gap}
  --white: #{$white}
  --color: #{$color}
  --colorAlpha: #{$colorAlpha}
  --colorAlpha2: #{$colorAlpha2}
  --colorLight: #{$colorLight}
  --colorOff: #{$colorOff}
  --background: #{$background}
  --bg-disabled: #{$backgroundDisabled}
  --bg-error: #{$backgroundError}
  --bg-warning: #{$backgroundWarning}
  --bg-success: #{$backgroundSuccess}
  --bg-info: #{$backgroundInfo}
  --primary: #{$color}
  --color2: #{$color2}
  --colorText: #{$colorText}
  --disabled: #{$colorAlpha2}
  --info: #{$colorInfo}
  --warning: #{$colorWarning}
  --danger: #{$colorDanger}
  --error: #{$colorDanger}
  --cancel: #{$colorDanger}
  --success: #{$colorSuccess}
  --colorTextGray: #{$colorTextGray}
  --colorTitle: #{$colorTitle}
  --colorToolbar: #{$color}
  --colorGray: #{$colorGray}

  --elevation0: #{$elevation0}
  --elevation1: #{$elevation1}
  --elevation2: #{$elevation2}
  --elevation2expand: #{$elevation2expand}
  --elevation3: #{$elevation3}
  --elevationChart: #{$elevationChart}
  --elevationModal: #{$elevationModal}
  --textShadow: #{$textShadow}

@media (prefers-reduced-motion)
  .react-loading-skeleton
    --pseudo-element-display: block !important

  // ::-webkit-scrollbar
  //   width: 10px
  //   overflow: hidden
  //   padding: 2px
  //   border-radius: $borderRadius
  //   background-color: var(--white)
  // ::-webkit-scrollbar-thumb
  //   border-radius: $borderRadius
  //   background-color: var(--background)
  // ::-webkit-scrollbar-thumb:hover
  //   background-color: var(--colorOff)
  // ::-webkit-scrollbar-button:start
  //   // display: block
  // ::-webkit-scrollbar-button:end
  //   // display: block

body
  //background: var(--background)
  background: white
  color: var(--colorText)
  fill: var(--colorText)
  margin: 0
  overflow-x: hidden
  &.dark, #layout.dark, #loginArea.dark
    // background: var(--background)
    // color: var(--colorText)
    // fill: var(--colorText)
    --white: #{$black}
    --color: #{$colorB}
    --colorAlpha: #{$colorBAlpha}
    --colorAlpha2: #{$colorBAlpha2}
    --colorLight: #{$colorBLight}
    --colorOff: #{$colorOffB}
    --background: #{$backgroundB}
    --colorText: #{$colorTextB}
    --colorTextGray: #{$colorTextGray}
    --colorTitle: #{$colorTitleB}
    --colorToolbar: #{$colorTitleB}
    --colorGray: #{$colorBGray}

    --elevation0: #{$elevation0B}
    --elevation1: #{$elevation1B}
    --elevation2: #{$elevation2B}
    --elevation2expand: #{$elevation2expandB}
    --elevation3: #{$elevation3B}
    --elevationChart: #{$elevationChartB}
    --elevationModal: #{$elevationModalB}
    --textShadow: #{$textShadowB}

  .badge-pill
    display: flex

    align-items: center
    justify-content: center

    background: var(--color)
    border-radius: $borderRadius
    color: var(--white)
    font-weight: bold
    padding: $gap

    &.info
      background-color: var(--info)
    &.warning
      background-color: var(--warning)
    &.danger
      background-color: var(--danger)
    &.success
      background-color: var(--success)
    &.normal
      background-color: var(--normal)
    &.disabled
      background-color: var(--disabled)
      color: var(--colorAlpha2)
    svg
      fill: var(--white)

  .form-control
    background: var(--colorText)
    color: var(--white)

body #root
  font-size: 14px
  *
    font-family: 'Roboto', sans-serif
    box-sizing: border-box

h2
  font-size: 20px
  margin: 0

h3
  font-size: 14px

h5
  font-size: 11px
  margin: 0
  padding: $gap calc($gap * 2)

ol
  margin: 0
  padding-left: 30px
  display: flex
  gap: $gap
  flex-direction: column
  li
    font-size: 14px

input[type="range"]
  -webkit-appearance: none
  width: 100%
  height: 10px
  background-color: #ccc
  border-radius: 5px
  border: 1px outset #ccc
  margin: 2px
input[type="range"]::-webkit-slider-thumb
  -webkit-appearance: none
  width: 20px
  height: 20px
  border: 1px outset $color2
  background-color: $color2
  border-radius: 50%
  box-shadow: 1px 1px 2px var(--colorTextGray)
  cursor: pointer
  transition: $transition
  &:active
    background-color: lighten($color2, 5%)
    border: 1px outset lighten($color2, 5%)
    box-shadow: 1px 1px 3px vaR(--colorTextGray)
    color: #ccc
.filters
  .buttons
    align-items: flex-end
    display: flex
    flex-direction: row
    height: 40px
    justify-content: flex-start

    button.submit
      height: 36px
      margin-left: 5px
      margin-right: 5px

details
  &> summary
    list-style: none
    min-height: 35px
    transition: $transition
    &:hover
      background: var(--colorOff)

  &:has(> div), &:has(> details)
    &> summary
      cursor: pointer
      font-weight: bold
      &::before
        content: '+'
        padding: 0 $padding
    &[open]
      &> summary
        &::before
          content: '-'

  &> div
    padding: $gap $gap $gap calc(3 * $padding)
    align-items: center
    &.item

  &> details
    margin: 0 0 0 calc(2 * $padding)
    &[open]
      border: 1px dashed var(--color)

.hidden
  display: none !important

.circle
  border-radius: 50% !important

.radius
  border-radius: $borderRadius

.overflow-hidden
  overflow: hidden

.box-shadow
  box-shadow: $elevation3

.border0
  border: none

.center
  justify-content: center
  align-content: center
  align-items: center
.center-v
  align-items: center
.center-h
  justify-content: center
  text-align: center
.center-margin
  margin: 0 auto

.nowrap
  white-space: nowrap

.right
  text-align: right
.left
  text-align: left

.hide
  display: none

.italic
  font-style: italic
.bold, .b
  font-weight: bold

.fcc
  display: flex
  flex: 1
  flex-direction: column
  justify-content: center
  text-align: center

.fit-content
  width: fit-content

.destaque
  color: var(--white)
  background: var(--color)
  padding: 2px $gap
  border-radius: $borderRadius
  font-weight: bold
  &.info
    background: $colorInfo
  &.warning
    background: $colorWarning
  &.danger
    background: $colorDanger
  &.success
    background: $colorSuccess
  &.normal
    background: var(--colorLight)
  svg
    fill: var(--white)

.p0
  padding: 0 * $spacing
.p1
  padding: 1 * $spacing
.p2
  padding: 2 * $spacing
.p3
  padding: 3 * $spacing
.p4
  padding: 4 * $spacing
.p5
  padding: 5 * $spacing
.p10
  padding: 10 * $spacing

.m0
  margin: 0 * $spacing
.m1
  margin: 1 * $spacing
.m2
  margin: 2 * $spacing
.m3
  margin: 3 * $spacing
.m4
  margin: 4 * $spacing
.m5
  margin: 5 * $spacing

.mt0
  margin-top: 0 * $spacing
.mt1
  margin-top: 1 * $spacing
.mt2
  margin-top: 2 * $spacing
.mt3
  margin-top: 3 * $spacing
.mt4
  margin-top: 4 * $spacing
.mt5
  margin-top: 5 * $spacing

.mr0
  margin-right: 0 * $spacing
.mr1
  margin-right: 1 * $spacing
.mr2
  margin-right: 2 * $spacing
.mr3
  margin-right: 3 * $spacing
.mr4
  margin-right: 4 * $spacing
.mr5
  margin-right: 5 * $spacing

.mb0
  margin-bottom: 0 * $spacing
.mb1
  margin-bottom: 1 * $spacing
.mb2
  margin-bottom: 2 * $spacing
.mb3
  margin-bottom: 3 * $spacing
.mb4
  margin-bottom: 4 * $spacing
.mb5
  margin-bottom: 5 * $spacing

.ml0
  margin-left: 0 * $spacing
.ml1
  margin-left: 1 * $spacing
.ml2
  margin-left: 2 * $spacing
.ml3
  margin-left: 3 * $spacing
.ml4
  margin-left: 4 * $spacing
.ml5
  margin-left: 5 * $spacing

.g0
  gap: 0 * $spacing
.g1
  gap: 1 * $spacing
.g2
  gap: 2 * $spacing
.g3
  gap: 3 * $spacing
.g4
  gap: 4 * $spacing
.g5
  gap: 5 * $spacing
.g6
  gap: 6 * $spacing
.g7
  gap: 7 * $spacing
.g8
  gap: 8 * $spacing
.g9
  gap: 9 * $spacing
.g10
  gap: 10 * $spacing

.h100
  height: 100%

.w100
  width: 100%
.w50
  width: 49%
  flex-basis: 49%
.w25
  width: 25%
  flex-basis: 25%
.w1-3
  width: 33.33%
  flex-basis: 33.33%
.w1-4
  width: 25%
  flex-basis: 25%
.w3-4
  width: 75%
  flex-basis: 75%
.w2-3
  width: 66.66%
  flex-basis: 66.66%

.f1
  flex: 1 0 200px
.f2
  flex: 2 0 200px
.f3
  flex: 3 0 200px
.f4
  flex: 4 0 200px
.f5
  flex: 5 0 200px
.f6
  flex: 6 0 200px
.f7
  flex: 7 0 200px
.f8
  flex: 8 0 200px
.f9
  flex: 9 0 200px
.f10
  flex: 10 0 200px
.f11
  flex: 11 0 200px
.f12
  flex: 12 0 200px

.f
  display: flex
.f-between
  justify-content: space-between
.f-column
  flex-direction: column
.f-row
  flex-direction: row
.f-wrap
  flex-wrap: wrap
  align-content: flex-start
.f-nwrap
  flex-wrap: nowrap
  align-content: flex-start
.f-stretch
  align-items: stretch
.f-start
  align-items: flex-start
.f-end
  align-items: flex-end

.relative
  position: relative
.absolute
  position: absolute
.sticky
  position: sticky

.ellipsis
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.b-info
  background: $colorInfo
.b-warning
  background: $colorWarning
.b-danger
  background: $colorDanger
.b-success
  background: $colorSuccess

.t-info
  color: $colorInfo
.t-warning
  color: $colorWarning
.t-danger
  color: $colorDanger
.t-success
  color: $colorSuccess

#lockerScreen
  width: 100vw
  height: 100vh
  justify-content: center
  align-items: center
  z-index: 1000
  backdrop-filter: blur(10px)
  position: absolute
  inset: 0

.observations
  gap: 5px
  display: flex
  flex-direction: column
  .item
    width: 100%
    padding: $gap
    border-left: 5px solid transparent
    border-left-color: var(--color)
    &.info
      border-left-color: var(--info)
    &.warning
      border-left-color: var(--warning)
    &.danger
      border-left-color: var(--danger)
    &.success
      border-left-color: var(--success)

.blink
  animation: blink 1.5s ease infinite
  outline-style: solid

.alert
  padding: 0 $padding
  border-radius: $borderRadius
  display: flex
  flex-direction: column
  gap: $gap
  $cor: $colorInfo
  $corf: $colorInfoLight
  &.danger
    $cor: $colorDanger
    $corf: $colorDangerLight
  &.warning
    $cor: $colorWarning
    $corf: $colorWarningrLight
  background: $corf
  h2
    border-bottom: 1px solid $cor
    line-height: 2em
    align-items: center
  h1, h2, h3, h4, h5
    margin: 0 0 $gap 0
    display: flex
    justify-content: space-between
    color: $cor
  .content
    color: $cor

.secondary-information
  color: var(--colorTextGray)
  font-size: 12px

// Animações

@keyframes fade-in
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes fade-out
  0%
    opacity: 1
  100%
    opacity: 0

@keyframes fade-in-blur
  0%
    opacity: 0
    filter: blur(10px)
  100%
    opacity: 1
    filter: blur(0px)

@keyframes fade-out-blur
  0%
    opacity: 1
    filter: blur(0px)
  100%
    opacity: 0
    filter: blur(10px)

@keyframes to-top
  0%
    opacity: 1
    transform: translateY(0px)
    filter: blur(0px)
  100%
    opacity: 0
    transform: translateY(-300px)
    filter: blur(10px)

@keyframes to-bottom
  0%
    opacity: 1
    transform: translateY(0px)
    filter: blur(0px)
  100%
    opacity: 0
    transform: translateY(300px)
    filter: blur(10px)

@keyframes from-top
  0%
    opacity: 0
    transform: translateY(-300px)
    filter: blur(10px)
  100%
    opacity: 1
    transform: translateY(0px)
    filter: blur(0px)

@keyframes from-bottom
  0%
    opacity: 0
    transform: translateY(300px)
    filter: blur(10px)
  100%
    opacity: 1
    transform: translateY(0px)
    filter: blur(0px)

@keyframes from-left
  0%
    opacity: 0
    transform: translateX(-300px)
    filter: blur(10px)
  100%
    opacity: 1
    transform: translateX(0px)
    filter: blur(0px)

@keyframes scale-in
  0%
    opacity: 0
    transform: scale(1.5)
  100%
    opacity: 1
    transform: scale(1)

@keyframes blink
  0%
    outline-width: 0px
    outline-offset: 0px
  100%
    outline-width: 7px
    outline-offset: 3px
    outline-color: transparent

@keyframes scale1-2
  0%
    transform: scale(1)
  100%
    transform: scale(1.2)

@keyframes animMapPath
  from
    stroke-dashoffset: 0
  to
    stroke-dashoffset: -105px

.animMapPath
  animation: animMapPath 10s linear infinite

.itinerary-map
  .toll-icon-popup-container
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center

    .toll-image-container
      background-position: center
      background-repeat: no-repeat
      background-size: contain
      max-height: 100px
      min-height: 70px
      width: 100px
