.icon-container
  position: relative
  display: inline-block

.icon-label
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  color: var(--white)
  font-weight: bold
  pointer-events: none
