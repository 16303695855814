.input-multi-filter
  .input-display
    position: relative
    display: flex
    align-items: center

    .input
      flex-grow: 1

  .modal
    .modal-body
      display: flex
      flex-direction: column
      gap: 10px

      .form-group
        align-items: center
        justify-content: center
        flex-direction: row

        label
          margin: -3px 0px 0px 0px
          width: 100%
          height: 50px

        input
          margin: 0
          border-radius: 4px 0px 0px 4px
          font-size: 16px
          height: 43px
          padding: 10px
          width: 100%

          &:disabled
            border: 1px solid var(--disabled)
            background-color: var(--bg-disabled)

        button
          border-radius: 0px 4px 4px 0px
          cursor: pointer
          height: 44px
          margin-left: 0
          padding: 10px

          &:hover
            height: 41px

      .list
        width: 600px

        .filter-items
          overflow: auto
          display: flex
          flex-wrap: wrap
          gap: 8px
          max-height: 300px

        .filter-item
          border: 1px solid var(--color)
          border-radius: 4px
          display: flex
          align-items: center
          padding: 0px
          right: 0

          span
            padding: 4px
            display: inline-block
            text-align: center
            vertical-align: middle
            width: 100%

          button
            border-radius: 0px 3px 3px 0px
            padding: 3px
            height: 100%
            margin: -1px 0px
            cursor: pointer

      .error-message, .hint
        border-radius: 3px
        display: block
        margin-bottom: 5px
        padding: 5px

      .error-message
        background-color: var(--bg-error)
        color: var(--error)

      .hint
        background-color: var(--bg-info)
        color: var(--info)
