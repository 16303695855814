#loginArea
  background: var(--white)
  background-size: cover
  opacity: 0
  animation: fade-in-blur .3s ease forwards

  #backlayer-login
    backdrop-filter: sepia(0.5) contrast(0.5) grayscale(0.5) blur(4px)
    width: 100%
    height: 100%
    background: var(--color)

    #login-area
      transition: $transition
      overflow: hidden
      opacity: 0
      animation: fade-in .7s .2s ease forwards
      background: linear-gradient(90deg, #fefefecc, #fefefeee, #fefefeee, #fefefeee, #fefefecc)
      background: var(--white)
      width: 75%
      box-shadow: var(--elevationModal)
      border-radius: $borderRadius
      padding: 10px 10px
      min-height: 50%
      @media (max-width: 1250px)
        min-height: 80%
        width: 80%

      #logoGrande
        position: relative
        @media (max-width: 1250px)
          display: none

        img#logo
          max-height: 70px
          max-width: 100%
          filter: drop-shadow(1px 1px 40px $colorTextGray)
          animation: scale-in .7s .4s ease forwards
          opacity: 0

        h3
          opacity: 0
          animation: fade-in .7s .4s ease forwards
          margin: 0
          text-align: center
          font-size: 17px

        #version
          position: absolute
          right: 65px
          top: 100px
          font-size: 60px
          font-family: cursive
          text-shadow: $elevation2
          color: #999

      .avatar
        img
          //box-shadow: 1px 1px 3px #333 inset
          //background: white
          border-radius: 50%
          mix-blend-mode: multiply
          padding: 5px

      #lang-selector
        button
          transition: $transition
          text-shadow: 1px 1px 1px #777
          &:hover
            text-shadow: 1px 1px 4px #777

      #right-area
        &> div
          opacity: 0
          animation: fade-in .7s .4s ease forwards
        img#logo
          max-width: 100%
          max-height: 70px
          filter: drop-shadow(1px 1px 40px $colorTextGray)
          animation: scale-in .7s .4s ease forwards
          opacity: 0
          display: none
          @media (max-width: 1250px)
            display: block

  &.dark
    #backlayer-login
      #login-area
        //background: linear-gradient(90deg, #343434bb, #343434ee, #343434ee, #343434ee, #343434bb)
