.text-group
  padding: 5px 0px 0px 0px
  position: relative
  flex-direction: column
  display: flex
  color: var(--colorText)
  //background: var(--white)

  &.h-field
    input
      min-height: 50px

    &.float
      input
        text-align: right

    [type='text'], [type='password'], [type='date'], [type='datetime-local'], [type='time'], [type='number']
      &:disabled
        border-color: var(--colorGray)
        background: var(--bg-disabled)
        border: 1px solid var(--colorGray)
        color: var(--colorGray)

        &:focus, &:active
          box-shadow: none
  &.w1-3
    width: 32.33%
    flex-basis: 32.33%
