.vertti-logo-loader
  svg
    width: 150px
    animation: vertti-logo-loader-amin 2s linear infinite
    .cls-1
      animation: vertti-logo-loader-cls-1-amin 2s linear infinite
      stroke: #1ad3af
      //stroke: #333
      fill: #1ad3af
      stroke-width: 1px
    .cls-2
      animation: vertti-logo-loader-cls-2-amin 2s linear infinite
      stroke: #00b6c4
      //stroke: #333
      fill: #00b6c4
      stroke-width: 1px

@keyframes vertti-logo-loader-cls-1-amin
  0%
    fill-opacity: 0.1
    filter: blur(1px)
    stroke-dasharray: 550px
    stroke-dashoffset: 550px
  50%
    filter: blur(0px)
    fill-opacity: 0.4
  100%
    fill-opacity: 0.1
    filter: blur(1px)
    stroke-dasharray: 550px
    stroke-dashoffset: -550px

@keyframes vertti-logo-loader-cls-2-amin
  0%
    fill-opacity: 0.1
    filter: blur(1px)
    stroke-dasharray: 440px
    stroke-dashoffset: 440px
  50%
    filter: blur(0px)
    fill-opacity: 0.4
  100%
    fill-opacity: 0.1
    filter: blur(1px)
    stroke-dasharray: 440px
    stroke-dashoffset: -440px

@keyframes vertti-logo-loader-amin
  0%

  50%
    //transform: scale(1.1)

  100%

#layout
  height: 100vh
  background: var(--background)
  transition: $transition

#toolbar
  transition: $transition
  position: fixed
  width: 100%
  z-index: 3
  top: 0
  height: fit-content
  padding: 10px 10px 15px
  align-items: center
  box-shadow: var(--elevation1)
  background: var(--colorToolbar)
  border-bottom-left-radius: 50% 15px
  border-bottom-right-radius: 50% 15px
  animation: from-top .6s ease forwards

  #toggleMenu, #toggleLang, #toggleDarkmode, .notifications
    color: $white

  img.logoVelog
    transform: scale(1.25)
    position: absolute
    left: 50%
    margin-left: -50px

  #toolbar_infos
    padding: 0 10px
    #user_name
      align-items: center
      color: var(--white)
      color: $white
      font-weight: bold
      font-size: 16px
      img
        //box-shadow: 1px 1px 3px #333 inset
        background: var(--white)
        background: $white
        border-radius: 50%
        height: 30px
        width: 30px
        padding: 5px

#sidebar
  overflow: hidden
  z-index: 1
  position: fixed
  height: 100%
  transition: $transition
  padding: 80px 0 10px 0
  box-shadow: var(--elevation2)
  flex-direction: column
  display: flex
  overflow-y: auto
  justify-content: space-between
  background: var(--white)
  width: 200px
  animation: from-left .6s ease forwards

  .menu
    transition: $transition
    .iconButton
      margin: -5px
    .submenu
      .iconButton
        margin: -5px
  &.reduce
    padding-right: 0
    min-width: auto
    max-width: 40px
    overflow: hidden
    .menu
      .menu-name
        white-space: nowrap
        opacity: 0
      li
        border-radius: 0
        &> span
          border-radius: 0
          padding: 10px
      ul.submenu
        li
          &:not(.active)
            display: none
          .menu-name
            white-space: nowrap
    &:hover
      max-width: 300px
      margin-right: -240px
      .menu
        .menu-name
          opacity: 1
        ul.submenu
          li:not(.active)
            display: block

#main
  display: flex
  //position: absolute
  //inset: 0
  //overflow-y: scroll
  padding: 80px 10px 10px 210px
  gap: $padding
  flex-direction: column
  //width: 100%
  transition: $transition
  &.reduce
    padding-left: 50px

  &> .frame
    //--aniPos: 0
    //opacity: 0
    //animation: from-bottom .6s ease forwards
    //animation-delay: calc( var(--aniPos) * .3s )

#version
  font-weight: bold
  font-size: 14px
  transition: $transition
  transition-delay: 0s
  //height: 50px
  .version-number
    transition: $transition
    transition-delay: 0s
    white-space: nowrap
  .version-env
    transition: $transition
    transition-delay: 0s
    white-space: nowrap
#sidebar.reduce:not(:hover)
  #version
    //height: 150px
    margin-top: 50px
    transform: translate(0px, -50px)
    .version-number
      transform: rotate(90deg)
      margin-top: 40px
    .version-env
      transform: rotate(90deg)
      margin-top: 80px

.version-env-big-stamp
  transition: $transition
  transition-delay: 0s
  white-space: nowrap
  position: fixed
  left: 50vw
  top: 50%
  z-index: 10000
  font-size: 90px
  transform: translate(-50%) rotate(25deg)
  opacity: .15
  border-radius: 50px
  padding: 5px 30px
  box-shadow: 1px 1px 20px black
  pointer-events: none

.ticketForPrint
  *
    font-family: monospace !important
    font-size: 12px
