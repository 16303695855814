.badge-container

  .badge-pill
    display: inline-block
    text-align: center
    justify-content: center
    background: var(--color)
    color: var(--white)
    font-weight: bold
    padding: 5px
    &.info
      background-color: var(--info)
    &.warning
      background-color: var(--warning)
    &.danger
      background-color: var(--danger)
    &.success
      background-color: var(--success)
    &.normal
      background-color: var(--normal)
    &.disabled
      background-color: var(--disabled)
      color: var(--colorAlpha2)
    &.sm
      width: 40px
      padding: 2px
    &.md
      width: 75px
      padding: 5px
    svg
      fill: var(--white)

