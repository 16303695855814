.contrato-card
  display: flex
  flex-direction: column
  gap: $gap
  position: relative
  flex: 0 0 32.7%
  padding: $padding
  box-shadow: var(--elevation2expand)
  border-radius: $borderRadius

  .status
    display: flex
    flex-direction: row-reverse
    color: var(--white)
    font-weight: bold
    border-bottom-left-radius: 20% 15px
  .fita-novo
    color: white
    right: 2px
    top: 2px
    padding: 2px 25px
    z-index: 1
    position: absolute
    font-size: 14px
    background: var(--info)
    font-weight: bold
  .header
    .first-row
      display: flex
      flex-wrap: wrap
      flex: 1
    .subsequent-rows
      display: flex
      flex-wrap: wrap
      flex: 1
      white-space: nowrap
    .actions
      display: flex
      flex-direction: column
      gap: $gap
      button
        padding: $gap
        max-width: initial
        width: 100%
        flex: 1
  .infos
    display: flex
    flex-direction: column
    flex: 1

    .info-block
      width: 100%
      display: flex
      justify-content: space-between
      margin: 1rem 0
      flex: 1

  .observations
    .item
      text-align: justify
      word-break: break-word
.title
  font-size: 14px
.title-bold
  font-size: 14px
  font-weight: bold
.featured-content
  font-weight: bold
  font-size: 14px
.content
  font-size: 14px
.sub-content
  font-size: 12px

.margin-right
  margin-right: 4px


.contrato-multidestinos-card
  display: flex
  //overflow: hidden
  flex-direction: row
  gap: $gap
  position: relative
  flex: auto
  padding: $padding
  box-shadow: var(--elevation2expand)
  width: max-content
  border-radius: $borderRadius
  .status
    display: flex
    flex-direction: row-reverse
    color: var(--white)
    font-weight: bold
    border-bottom-left-radius: 20% 15px
  .fita-novo
    color: white
    right: 2px
    top: 2px
    padding: 2px 25px
    z-index: 1
    position: absolute
    font-size: 14px
    //transform: rotate(45deg) translate(22px, -10px)
    //background: linear-gradient(90deg, rgba(45, 168, 240, .5), rgba(45, 168, 240, 1), rgba(45, 168, 240, .5))
    //box-shadow: 1px 0 3px #444
    background: var(--info)
    font-weight: bold
  .header
    display: flex
    gap: $gap
    align-items: center
    .base
      gap: $gap
      display: flex
      flex-direction: column
      flex: 1
    .actions
      display: flex
      flex-direction: column
      gap: $gap
      button
        padding: $gap
        max-width: initial
        width: 100%
        flex: 1
  .infos
    gap: $gap
    display: flex
    flex-wrap: wrap
    &> div
      flex: 1
      width: 32%

.cnc-card
  flex: 1
  padding: 10px
  box-shadow: var(--elevation2expand)
  border-radius: $borderRadius

.cnm-card
  flex: 2
  background-color: #ffcccc
  box-shadow: var(--elevation2expand)
  border-radius: $borderRadius

.contrato-editar
  ul.contrato-pausas
    list-style: none
    padding: 0
    gap: 5px
    display: flex
    flex-direction: column
    margin: 0
    li
      display: flex
      justify-content: space-between
      align-items: center
      &.paused
        text-decoration: line-through
        color: #999

.obsteste
  &::before
    content: 'Mostrar'

.flex-1
  flex: 1

