@import '../../s/vars'

.freight-controllership
  .frame
    margin-bottom: 5px
    margin-top: 5px

  .frame-control-records
    display: flex
    flex: 1
    align-items: center
    justify-content: flex-end

  .travel-records
    &.records
      .frame-title-control
        display: flex
        align-items: center
        justify-content: center

        .frame-buttons-view
          width: auto

  .invoice-records
    &.records
      .chat
        &.observations
          min-height: 55px
          max-height: 200px

        &hidden
          display: none

      .invoicing-card
        border-radius: $borderRadius
        box-shadow: var(--elevation2expand)
        display: flex
        flex-basis: 49%
        flex-direction: column
        gap: $gap
        margin-left: 0.25%
        margin-right: 0.25%
        padding: $padding
        position: relative
        width: 100%

        .status
          border-bottom-left-radius: 20% 15px
          display: flex
          flex-direction: row-reverse
          font-weight: bold

        .fita-novo
          background: var(--info)
          color: white
          font-size: 14px
          font-weight: bold
          padding: 2px 25px
          position: absolute
          right: 2px
          top: 2px
          z-index: 1

        @media (max-width: 1024px)
          flex-basis: 100%

        .header
          display: flex
          gap: $gap
          align-items: center

          .header-content
            position: relative
            background-color: var(--colorTitle)
            .inner-container
              width: 80%
              flex-wrap: wrap
              justify-content: space-between
              padding: 12px 0px 12px 12px
              .info-block
                display: flex
                flex-direction: column
                gap: 10px
                flex-wrap: wrap
                flex: 1
                .info-section
                  display: flex
                  flex-direction: column
                  gap: 5px
            .title
              font-size: 0.75rem
            .featured-content
              font-weight: bold
              font-size: 1rem
            .content
              font-size: 0.875rem

          .base
            display: flex
            flex-direction: column
            flex: 1
            gap: $gap

          .actions
            display: flex
            flex-direction: column
            gap: $gap

            button
              flex: 1
              max-width: initial
              padding: $gap
              width: 100%

        .infos
          display: flex
          flex-wrap: wrap
          gap: $gap

          &> div
            flex: 1
            width: 32%

        .observations
          .item
            text-align: justify
            word-break: break-word

        .card-number
          display: flex
          flex-wrap: nowrap
          font-size: 22px
          font-weight: bold
          margin-left: 10px
          margin-right: 15px

        .margin-top
          margin-top: 3.1rem

        @media (max-width: 1520px)
          margin-top: 0

        .margin-right
          margin-right: 6rem

        .white-space
          white-space: nowrap

        .table-default
          &.paginated
            min-height: 520px
