.summaries-chart
  width: 100%
  display: flex
  flex-direction: column
  gap: 10px

  .chart-container
    flex: 1

  .legend-container
  ul
    padding: 0
    list-style: none

    .legend-item
      display: flex
      align-items: baseline
      margin-bottom: 5px
      cursor: pointer
      transition: font-size 0.1s ease

      &:hover
        font-size: 1.01em
        font-weight: 600

      .color-box
        width: 10px
        height: 10px
        margin-right: 10px

      .name
        font-weight: bold
        margin-right: 10px

      .quantity
        margin-left: 10px

      .percentage
        margin-left: 10px
