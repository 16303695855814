@import './modal/'
@import './table/'
@import './daterangePicker/'

.container
  display: flex
.row
  display: flex
  flex-direction: row
  //flex: 1
  > *
    display: flex
    //flex: 1
.col
  display: flex
  flex-direction: column

.text-group
  padding: 5px 0px 0px 0px
  position: relative
  flex-direction: column
  display: flex
  color: var(--colorText)
  //background: var(--white)

  .select-control__control
    color: var(--colorText)
    background: var(--white)
    overflow: hidden
    flex-wrap: nowrap
    min-width: 100px
  .select-control__multi-value__remove
    color: $color // aqui é $color mesmo!
  .select-control__placeholder
    color: var(--color)
    font-size: 14px
  .select-control__single-value
    color: var(--colorText)
  .select-control__indicator
    cursor: pointer
    background: var(--white)
    padding: 0
  .select-control__indicator-separator
    margin: 0

  span.label
    position: absolute
    white-space: nowrap
    margin: -6px 6px 0
    background: var(--white)
    width: min-content
    padding: 0 5px
    font-size: 11px
    font-weight: bold
    border-radius: 6px

  //[type="date"]::-webkit-calendar-picker-indicator
  [type="password"]
    &::-ms-clear, &::-ms-reveal
      display: none
  [type='text'], [type='password'], [type='date'], [type='datetime-local'], [type='time'], [type='number']
    color: var(--colorText)
    background: var(--white)
    padding: $padding
    &::placeholder
      color: var(--colorLight)
  [type='date'], [type='datetime-local'], [type='time']
    min-width: 110px
  [type='number']
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button
      -webkit-appearance: none
      margin: 0
  &> [type='text'], &> [type='password'], &> [type='date'], &> [type='datetime-local'], &> [type='time'], &> [type='number'], .select-control__control
    position: relative
    border-radius: $borderRadius
    font-size: 14px
    border: 1px solid var(--colorText)
    background: var(--background)
    color: var(--colorText)
    outline: none
    box-shadow: none
    &:focus, &:active, &.select-control__control--is-focused
      box-shadow: 0 0 3px rgba(var(--color), .9) inset
    &:disabled
      border: none
      background: var(--white)
      &:focus, &:active
        box-shadow: none
  .info
    color: var(--colorTextGray)
    font-size: 12px
    padding: 5px
    &.danger
      color: $colorDanger

  &.icon
    [type='text'], [type='password'], [type='date'], [type='datetime-local'], [type='time'], [type='number']
      padding: $padding 32px $padding $padding
    .iconButton
      position: absolute
      padding: 2px
      margin: calc($padding / 2)
      right: 0
  &.error
    span.label
      color: $colorWarning
    input, .select-control__control
      border: 2px solid $colorWarning
      //background: lighten($colorWarning, 40%)
      &::placeholder, .select-control__placeholder, .select-control__indicator
        color: $colorWarning
      .select-control__indicator-separator
        background-color: $colorWarning
  &.fit
    [type='text'], [type='password'], [type='date'], [type='datetime-local'], [type='time'], [type='number']
      padding: $gap
    .select-control__control
      min-height: 30px

.select-control
  &__input-container
    color: var(--colorText) !important
  &__menu, .react-select__menu
    color: var(--color) !important
    background: var(--background) !important
  &__option
    font-size: 12px
    padding: $gap
    transition: $transition
    &--is-selected
      font-weight: bold
      background: var(--color) !important
      color: var(--white) !important
    &:hover
      font-weight: bold
      cursor: pointer
      background: var(--colorOff) !important
      color: var(--colorText) !important
      border-radius: $borderRadius

button, .button
  text-decoration: none
  font-size: 14px
  //border: 1px solid var(--color)
  border: none
  background: var(--color)
  color: var(--white)
  display: flex
  padding: calc($padding/2) $padding
  justify-content: center
  align-items: center
  max-width: fit-content
  gap: $gap
  font-weight: bold
  width: fit-content
  border-radius: $borderRadius
  outline: none
  box-shadow: var(--elevation1)
  cursor: pointer
  transition: $transition
  text-shadow: -1px -1px 1px #0003
  outline-width: 2px
  outline-style: solid
  outline-color: transparent
  &.small
    font-size: 10px
  &:hover
    box-shadow: var(--elevation2)
    background: var(--colorAlpha)
    outline-color: var(--color)
  &:active
    background: var(--colorAlpha2)
    transform: translate(1px, 1px)

  &.info, &.success, &.danger, &.warning, &.primary
    color: white

    svg:
      color: white

    &:disabled, &[disabled], &.disabled
      color: var(--colorAlpha)

      svg:
        color: var(--colorAlpha)

  &.info
    background: $colorInfo !important
    &:hover
      background: fade-out($colorInfo, 0.3) !important
      outline-color: $colorInfo !important
    &:active
      background: fade-out($colorInfo, 0.5) !important

  &.success
    background: $colorSuccess !important
    &:hover
      background: fade-out($colorSuccess, 0.3) !important
      outline-color: $colorSuccess !important
    &:active
      background: fade-out($colorSuccess, 0.5) !important

  &.warning
    background: $colorWarning !important
    &:hover
      background: fade-out($colorWarning, 0.3) !important
      outline-color: $colorWarning !important
    &:active
      background: fade-out($colorWarning, 0.5) !important

  &.danger
    background: $colorDanger !important
    &:hover
      background: fade-out($colorDanger, 0.3) !important
      outline-color: $colorDanger !important
    &:active
      background: fade-out($colorDanger, 0.5) !important

  &:disabled, &[disabled], &.disabled
    background: var(--colorAlpha2) !important
    text-shadow: none
    color: var(--colorAlpha)
    box-shadow: none
    cursor: auto
    outline: none !important
    &:hover
      background: var(--colorAlpha2) !important
      outline: none !important
    &:active
      transform: none
      outline: none !important

  &.iconButton
    color: inherit
    background: transparent
    box-shadow: none
    border-radius: $borderRadius
    outline: 1px solid transparent
    display: flex
    align-items: center
    gap: $padding
    padding: calc($padding / 2)
    border: none
    transition: $transition
    &:active, &.selected
      outline: 1px solid var(--colorLight)
      background: var(--colorLight)
      color: var(--white)
    &:disabled
      background: var(--colorOff)
      border: 1px solid rgba(var(--colorOff), .7)
      color: var(--colorAlpha)
      outline: none
      text-shadow: 1px 1px 1px hsl(var(--color-off), 50%, 85%)
      &:hover
        background: var(--colorOff)
        border: 1px solid rgba(var(--colorOff), .7)
        color: var(--colorAlpha)
        outline: none
    a
      color: var(--colorText)

.avatar
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%
  padding: 2px
  width: 40px
  height: 40px
  transition: $transition
  &.border
    border: 2px solid
    &:hover
      border: 2px solid transparent
  &.s48
    width: 40px
    height: 40px
  &.s240
    width: 232px
    height: 232px

.dropdown-menu
  .dropdown-menu-clicker
    cursor: pointer
  ul
    position: absolute
    animation: fade-in .3s ease forwards
    z-index: 2
    list-style: none
    padding: 0
    border: 2px solid var(--color)
    color: var(--color)
    margin: 5px 0 0 -5px
    background: var(--white)
    box-shadow: var(--elevation3)
    border-radius: $borderRadius
    min-width: 175px

    .menu-item
      display: flex
      justify-content: space-between
      align-items: center

    .sub-menu
      display: none
      position: absolute
      top: -7px
      list-style: none
      padding: 0
      border: 2px solid var(--color)
      color: var(--color)
      margin: 5px 0 0 -5px
      background: var(--white)
      box-shadow: var(--elevation3)
      border-radius: $borderRadius
      z-index: 1000

      &.right
        left: calc(100% + 2px)

      &.left
        right: calc(100% + 2px)

      li
        transition: $transition
        cursor: pointer
        padding: $gap
        font-size: 14px
        border-radius: calc( $borderRadius / 2 )
        display: flex
        align-items: center
        gap: $spacing

    li
      align-items: center
      border-bottom: 1px solid var(--colorOff)
      border-radius: calc( $borderRadius / 2 )
      cursor: pointer
      display: flex
      font-size: 14px
      gap: $spacing
      padding: $gap
      transition: $transition
      display: block

      &:hover
        box-shadow: var(--elevation0)
        color: var(--white)
        background: var(--color)

        svg
          color: var(--white)

        .checkbox-group
          .checkbox-anim
            &:before
              border: 2px solid var(--white)

      &:active
        background: var(--colorLight)
        box-shadow: var(--elevation1)
        color: var(--colorText)
        outline: 1px solid var(--colorLight)

      &.disabled
        cursor: context-menu
        color: var(--colorTextGray)
        outline: none

        svg
          color: var(--colorTextGray)

        &:hover, &:active
          color: var(--colorText)
          box-shadow: none
          font-weight: normal
          background: var(--disabled)

          svg
            color: var(--colorText)

      .submenu-icon
        margin-left: auto
        padding-left: 5px
        min-width: 20px

        &+ svg
          margin-right: 5px

      &:hover > .sub-menu
          display: block

  &.dropdown-menu-above
    position: relative
    ul
      bottom: 0
      left: 40px

  &.dropdown-menu-below
    ul
      left: 260px
      top: 220px

ul.menu
  margin: 0
  padding: 0
  list-style: none
  display: flex
  flex-direction: column
  //gap: $gap
  &> li
    display: flex
    flex-direction: column
    overflow: hidden
    &.active
      &> span
        background: var(--color)
        color: var(--white)
    &> span
      display: flex
      align-items: center
      justify-content: space-between
      padding: 10px
      gap: 5px
      font-weight: bold
      cursor: pointer
      transition: $transition
      &> span
        display: flex
        gap: 5px
      &:hover
        background: $color2
        color: white

    ul.submenu
      padding: 0
      max-height: 0
      list-style: none
      flex-direction: column
      gap: 0px
      transition: .3s ease all
      li
        display: flex
        flex-direction: column
        overflow: hidden
        transition: $transition
        .menu-icon
          &.dot-inactive
            display: flex
          &.dot-active
            display: none
        &> span
          padding: 10px 10px 10px 30px
          align-items: center
          justify-content: space-between
          font-weight: bold
          cursor: pointer
          display: flex
          gap: 5px
          &> span
            display: flex
            gap: 5px
        &.active
          background: var(--colorLight)
          color: var(--white)
          .menu-icon
            &.dot-inactive
              display: none
            &.dot-active
              display: flex
        &:hover
          background: $color2
          color: $white
          &> span
            font-weight: bold

    &.active, &:hover
      ul.submenu
        max-height: 200px

.frame
  border-radius: $borderRadius
  box-shadow: var(--elevation3)
  background: var(--white)
  &:hover
    //box-shadow: var(--elevationChart)
  .frame-titleBar
    border-bottom: 1px solid var(--colorOff)
    display: flex
    justify-content: space-between
    align-items: center
    gap: $padding
    background: var(--colorTitle)
    .frame-buttons-view
      display: flex
      align-items: center
      flex-direction: row
      gap: $padding
      background: var(--colorTitle)
      padding-left: $padding
      :disabled
        background: none !important
      :hover
        color: #00c1de
        background: none !important
    h3
      margin: 0
      padding: $padding
    .frame-actions
      gap: calc($padding / 2)
      display: flex
      .iconButton
        a
          height: 24px
    .frame-title-control
      flex: 1
      &> *
        width: 100%
        padding: 0
  .frame-content
    //align-items: baseline
    width: 100%
    padding: $padding
    flex-wrap: wrap
    flex: 1
    //display: flex
    gap: $padding
  &.noPadding
    .frame-content
      padding: $padding 0

.card
  background: var(--white)
  border-radius: $borderRadius
  box-shadow: var(--elevation3)
  flex: 1
  display: flex
  flex-direction: column
  min-width: 200px
  .card-content
    padding: 10px 10px 5px
    gap: $spacing
    display: flex
    justify-content: space-between
    //border-bottom: 1px solid var(--colorOff)
    .card-icon
      flex: 1
    .card-value
      font-size: 24px
      text-align: right
      flex: 1
  .card-title
    padding: 5px 10px 10px
    font-size: 12px
    display: flex
    justify-content: flex-end
    &:has(.card-percent)
      justify-content: space-between
    .card-percent
      display: flex
      gap: 2px
      &::before
        font-size: 18px
        line-height: 12px
      &.plus
        color: green
        &::before
          content: "\2191"
      &.minus
        color: red
        &::before
          content: "\2193"

  &.normal
    border-left: 5px solid transparent
  &.info
    border-left: 5px solid $colorInfo
  &.danger
    border-left: 5px solid $colorDanger
  &.warning
    border-left: 5px solid $colorWarning
  &.success
    border-left: 5px solid $colorSuccess

  &.normal-b
    border-top: 5px solid transparent
  &.info-b
    border-top: 5px solid $colorInfo
  &.danger-b
    border-top: 5px solid $colorDanger
  &.warning-b
    border-top: 5px solid $colorWarning
  &.success-b
    border-top: 5px solid $colorSuccess

.react-tabs
  flex: 1
  width: 100%
  display: flex
  flex-direction: column
  //gap: $gap
  .react-tabs__tab-list
    overflow: auto
    display: flex
    gap: 5px
    justify-content: left
    text-align: center
    border: 0
    margin: 0 0
    padding: 0
    list-style: none
    margin-bottom: $gap
    .react-tabs__tab
      transition: $transition
      font-size: 14px
      padding: $padding
      border-radius: $borderRadius $borderRadius 0 0
      cursor: pointer
      list-style: none
      border-left: 1px solid gray
      border-top: 1px solid gray
      border-right: 1px solid gray
      border-bottom: 0px solid gray
      &.react-tabs__tab--selected
        box-shadow: var(--elevation1)
        font-weight: bold
        color: var(--white)
        background: rgba( var(--color), .6)
        background: var(--color)
      &:hover
        color: var(--white)
        box-shadow: var(--elevation1)
        background: $color2
      span.destaque
        margin-left: 5px
        margin-right: -5px

  .react-tabs__tab-panel
    display: flex
    &.react-tabs__tab-panel--selected

.divider
  margin: $gap 0
  color: gray
  display: flex
  align-items: center
  &.light-border
    .divider-line
      border-bottom: 1px solid #F0F0F0
  &.default-border
    .divider-line
      border-bottom: 1px solid gray
  .divider-line
    //width: 100%
    flex: 1
    border-bottom: 1px solid gray
  .divider-text
    text-align: center
    flex: 1
    max-width: fit-content
    padding: $gap calc($gap * 4)

.upload
  .upload-label
    padding: $padding 0
    font-size: 10px
  .dropzone
    cursor: pointer
    background: var(--colorOff)
    border-radius: $borderRadius
    border: 1px dashed $color
    width: 100%
    //height: 100px
    padding: 20px
    display: flex
    justify-content: center
    align-items: center
    box-shadow: 0px 1px 3px var(--colorTextGray) inset
  ul.upload-list
    margin: 0
    list-style: none
    padding: 0
    li
      display: flex
      justify-content: space-between
      cursor: default
      align-items: center
      padding: calc($padding / 2) $padding
      transition: $transition
      &> div
        display: flex
        gap: $padding
        a
          color: $colorText
      &:hover
        background: $colorOff

.span
  //padding: 10px 0px 5px 0px
  padding: 5px 0px 0px 0px
  position: relative
  flex-direction: column
  display: flex
  min-width: 150px
  .span-value
    padding: $padding
    position: relative
    border-radius: $borderRadius
    font-size: 14px
    // display: flex
    // align-items: center
    // gap: $gap
    .span-value-sub
      // display: flex
      // align-items: center
      // gap: $gap
      color: #777
      font-size: 12px
      svg
        vertical-align: middle
  .span-label
    position: absolute
    white-space: nowrap
    margin: -6px 6px 0
    background: var(--white)
    width: min-content
    padding: 0
    font-size: 11px
    font-weight: bold

  &.fit
    .span-value
      padding: $gap

.chat
  width: 100%
  overflow: auto
  margin-top: 20px
  .cloud
    position: absolute
    width: 100%
    height: 30px
    background: linear-gradient(0deg, transparent, hsla(0,0%,100%,.7))
    display: flex
    align-items: center
    justify-content: center
    opacity: 1
    z-index: 1
    transition: opacity 3s
    pointer-events: none
  .message
    position: relative
    width: 70%
    height: auto
    display: inline-block
    padding: 0px 5px 25px 5px
    background: var(--background)
    overflow: visible
    border-radius: 10px
    margin-bottom: 5px
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4)
  .time
    position: absolute
    display: block
    padding: 2px
    font-weight: bold
    margin: 5px 5px 5px
    font-size: 10px

.stepper
  display: flex
  gap: 5px
  justify-content: space-between
  padding: 10px
  .step-line
    background: $colorTextGray
    height: 1px
    flex: 2
    margin: 14px 0
  .step-item
    cursor: pointer
    display: flex
    flex-direction: column
    gap: $gap
    align-items: center
    flex: 1
    transition: $transition
    .step-number
      transition: $transition
      justify-content: center
      align-items: center
      display: flex
      padding: 7px
      border-radius: 50%
      color: $colorTextGray
      background: radial-gradient(fade-out($colorTextGray, 0.9) 30%, $colorTextGray 80%, $colorTextGray 110%) -2px -3px
      background: $colorTextGray
      color: white
      box-shadow: var(--elevation1)
      aspect-ratio: 1
      width: fit-content
      text-align: center
      font-weight: bold
      font-size: 12px
    .step-label
      font-size: 12px
      text-align: center
      white-space: nowrap
    &.error
      .step-number
        color: darken($colorDanger, 5%)
        background: radial-gradient(fade-out($colorDanger, 0.9) 30%, $colorDanger 80%, $colorDanger 110%) -2px -3px
        background: $colorDanger
        color: white
      .step-label
        color: darken($colorDanger, 5%)
    &.done
      .step-number
        color: darken($colorSuccess, 5%)
        background: radial-gradient(fade-out($colorSuccess, 0.9) 30%, $colorSuccess 80%, $colorSuccess 110%) -2px -3px
        background: $colorSuccess
        color: white
      .step-label
        color: darken($colorSuccess, 5%)
    &.active
      transform: scale(1.2)
      .step-number
        box-shadow: var(--elevation3)
        color: darken($colorInfo, 5%)
        background: radial-gradient(fade-out($colorInfo, 0.9) 30%, $color3 80%, $color3 110%) -2px -3px
        background: $colorInfo
        color: white
      .step-label
        color: darken($colorInfo, 5%)
        text-shadow: var(--elevation3)
    &.disabled
      cursor: not-allowed
      opacity: 0.5

.dot
  border-radius: 50%
  box-shadow: var(--elevation1)
  &.blink
    animation: blink 1.5s ease infinite
    outline-style: solid

.recharts-surface
  filter: drop-shadow(var(--elevationChart))
.recharts-default-tooltip
  background-color: var(--white) !important
  color: var(--color) !important
.recharts-wrapper
  tspan
    fill: var(--colorText)
.recharts-tooltip-wrapper
  font-size: 12px
  .recharts-default-tooltip
    padding: 5px !important

.badge
  position: relative
  .badge-value
    font-weight: bold
    position: absolute
    top: -5px
    right: -5px
    border-radius: 7px
    font-size: 8px
    padding: 3px
    color: $white
    min-width: 16px
    text-align: center
    box-shadow: var(--elevation1)

.notifications
  ul
    gap: $gap
    display: flex
    flex-direction: column
    max-height: 90vh
    overflow-y: auto
    li
      display: flex
      justify-content: space-between
      gap: $gap
      font-size: 12px
      &.action
        cursor: pointer
      &:not(:last-child)
        border-bottom: 1px solid $colorOff
      .notification-info
        gap: $gap
        display: flex
        flex-direction: column
        width: 100%
        cursor: pointer
        .notification-msg
          width: 100%
          &.notification-msg__unread
            font-weight: bold

        .notification-date
          width: 100%
          color: $colorTextGray
          text-align: left
          font-size: 11px

label.checkbox-group
  cursor: pointer
  input[type="checkbox"]
    display: none
    & + span.checkbox-anim
      display: block
      position: relative
      padding-left: 20px
      //margin-bottom: 20px
      font: 14px/20px 'Open Sans', Arial, sans-serif
      color: #ddd
      cursor: pointer
      &:before
        content: ''
        display: block
        width: 10px
        height: 10px
        border: 2px solid var(--color)
        position: absolute
        left: 5px
        top: 0
        opacity: .5
        transition: all .3s
    &:checked
      & + span.checkbox-anim
        &:before
          width: 3px
          height: 8px
          top: -2px
          left: 7px
          border-radius: 0
          opacity: 1
          border-width: 2px
          border-top-color: transparent
          border-left-color: transparent
          transform: rotate(45deg)

  &.column-field
    input[type="checkbox"]
      & + span.checkbox-anim
        &:before
          height: 15px
          left: 3px
          top: -10px
          width: 15px
      &:checked
        & + span.checkbox-anim
          &:before
            height: 12px
            left: 7px
            top: -10px
            width: 6.5px

  .info
    color: lighten($colorText, 30%)
    font-size: 12px
    padding: 5px

.switch
  position: relative
  display: inline-block
  width: 40px
  height: 24px
  display: flex
  flex-direction: column
  &.with-label
    padding: 10px 0px 5px 0px
    height: 45px
    .switch-container
      position: relative
      margin-top: 5px
  .label
    position: absolute
    white-space: nowrap
    margin: -6px 6px 0
    width: min-content
    padding: 0
    font-size: 11px
    font-weight: bold
  input
    opacity: 0
    width: 0
    height: 0
    margin: 0
  .switch-slider
    position: absolute
    cursor: pointer
    inset: 7px
    background-color: #ccc
    transition: 0.4s
    height: 10px
    width: 26px
    border-radius: 5px
    &:before
      box-shadow: 1px 1px 2px $colorTextGray
      position: absolute
      content: ''
      height: 16px
      width: 16px
      left: -4px
      bottom: -2px
      background-color: #fafafa
      transition: 0.4s
      border-radius: 50%
  &.checked
    .switch-slider
      background-color: desaturate( lighten($color2, 40%), 60%)
      &:before
        transform: translateX(16px)
        background-color: $color2
  &.disabled
    .switch-slider
      background-color: #bdbdbd
      &:before
        background-color: #bdbdbd

.react-loading-skeleton
  --base-color: #9993 !important
  --highlight-color: #9999 !important

.flagIcon
  width: 16px

.switch-button
  min-width: fit-content
  height: 38px
  border-radius: $borderRadius
  box-shadow: 1px 1px 3px var(--colorOff) inset
  background: var(--background)
  display: flex
  overflow: hidden
  border: 1px solid var(--color)
  .switch-button-option
    color: var(--colorText)
    cursor: pointer
    padding: $padding
    &.selected
      font-weight: bold
      background: var(--color)
      color: var(--white)

.card-group
  align-items: center
  border-radius: $borderRadius
  box-shadow: var(--elevation3)
  display: flex
  flex-direction: column
  justify-content: center
  padding: $gap

  &.info, &.success, &.warning, &.danger
    border-top-style: solid
    border-top-width: 5px

  &.info
    border-top-color: var(--info)

  &.success
    border-top-color: var(--success)

  &.warning
    border-top-color: var(--warning)

  &.danger
    border-top-color: var(--danger)

  .card-group-title
    font-size: 14px
    font-weight: bolder
    padding: 5px
    text-align: center
    width: 100%

  .card-group-filters
    display: flex
    flex-direction: row
    width: 100%

  .card-item
    border-radius: 4px
    border: 1px solid transparent
    box-shadow: var(--elevation3)
    cursor: pointer
    display: flex
    flex-direction: column
    flex: 1
    font-size: 16px
    justify-content: space-between
    transition: $transition
    width: 120px

    &.active
      background: var(--background)
      border: 1px dashed var(--color)

    &:hover
      background: var(--colorOff)

    .card-value
      font-weight: bolder
      text-align: right

    .card-percent
      font-weight: bold
      font-size: 10px
      color: var(--colorGray)

    .card-title
      font-size: 12px
      line-height: 12px
      color: var(--colorGray)
      white-space: nowrap

.iconButton
  color: var(--black)
  margin-left: 1px
  margin-right: 1px

  svg.info
    color: var(--info)

  svg.success
    color: var(--success)

  svg.warning
    color: var(--warning)

  svg.danger
    color: var(--danger)

  &:active
    color: var(--white)

  &.disabled, &[disabled]
    color: var(--colorAlpha)
    svg
      color: var(--colorAlpha)
      
.badge-success
  border-width: 1px
  border-radius: 12px
  border-style: solid
  border-color: #10dd90 !important
  color: #10dd90
  font-size: 10px
  padding: 4px


.badge-info
  border-width: 1px
  border-radius: 12px
  border-style: solid
  border-color: #25252580
  color: #25252580
  font-size: 10px 
  padding: 4px