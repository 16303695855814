.quotation
  display: flex
  flex-direction: column

  .container
    display: flex
    flex-direction: column
    flex-wrap: wrap

    .details-group
      display: flex
      flex-direction: column
      width: 100%
      margin-bottom: 0.5rem
      gap: 0.5rem

    h3
      font-weight: bold
      margin: 0.5em 0

    .transport-information-group
      display: flex
      margin-bottom: 0.5rem

      .info-item
        margin-right: 1rem

    .observation-group
      display: flex
