.background-blur
  width: 100%
  height: 100%
  backdrop-filter: blur(5px)
  filter: blur(5px)
  opacity: 0.8
  pointer-events: none

.table-default
  background: var(--white)
  table
    flex: 1
    width: 100%
    thead
      tr
        th
          &:has(.force-fit), &.force-fit
            width: 1%
          &> div
            text-align: left
            font-size: 13px
            padding: $gap
            white-space: nowrap
            display: flex
            align-items: center
            gap: $gap
            .table-order-buttons
              svg
                margin: -6px -10px -6px 0
                opacity: .3
                &.selected
                  opacity: 1
          &.sortable
            &> div
              cursor: pointer
        &.table-filters
          th
            label.text-group
              //min-width: auto
              padding: 0
              width: 100px
              input
                padding: $gap
                font-size: 12px
              .iconButton
                display: flex
                padding: 0px
                margin: 4px
                border: 0
                line-height: 14px
                height: 18px
                align-items: center
                aspect-ratio: 1
                svg
                  width: 16px

    tbody
      tr
        transition: $transition
        &.clickable
          cursor: pointer
        &.disabled
          opacity: 0.5
        td
          &:has(.force-fit), &.force-fit
            width: 1%
          font-size: 13px
          padding: $gap
          transition: $transition
          a
            color: var(--colorText)
          svg
            //fill: var(--colorGray)
          .destaque
            svg
              fill: var(--white)

          .downloads
            min-width: 335px
            width: 100%
            opacity: 0.8

            button
              font-size: 12px
              margin: 0px
              padding: 2px
              max-width: initial
              min-width: 13%

        &:nth-child(2n-1)
          td
            background: var(--background)
            .downloads
              opacity: 1

        &:hover
          &> td
            background: var(--colorOff) !important
            .downloads
              opacity: 1
        &.selected
          position: relative
          box-shadow: var(--elevation3)
          //transform: scale(1.02)
          font-weight: bold
          td
            background: var(--colorOff)
        &.table-sub
          &> td
            width: 100%
            &> *
              border-radius: $borderRadius
              border: 1px dashed var(--color)
              width: 100%
          &:hover
            &> td
              background: none !important
        &.disabled-line
          td
            text-decoration: line-through
            color: var(--colorLight)

  .table-footer
    display: flex
    justify-content: space-between
    padding: 0 $gap
    select
      padding: calc( $padding / 2 )
      border: none
      background: var(--colorOff)
      border-radius: $borderRadius
      color: var(--color)
      transition: $transition
      outline: 2px solid tranparent
      cursor: pointer
      &:hover
        outline: 2px solid var(--color)
    .pagination
    .per-page
